import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../../components/layout"
import ContentPage from "../../../layouts/contentPage"

import Seo from "../../../components/seo"

import ProductHero from "../../../components/Product/productHero"
import H1 from "../../../components/h1"
import ButtonColor from "../../../components/buttonColor"
import ProductInfo from "../../../components/Product/productInfo"
import ProductPackshot from "../../../components/Product/productPackshot"
import ProductPackshotBubbles from "../../../components/Product/productPackshotBubbles"
import BubbleBg from "../../../components/Product/bubbleBg"
import ProductSidebar from "../../../components/Product/productSidebar"
import More from "../../../components/more"
import Tabs from "../../../components/Tabs/tabs"
import ProductDescription from "../../../components/Product/productDescription"
import Relift from "../../../images/media/products/relift/relift.svg";
import Ingredients from "../../../components/Product/ingredients"
import Ingredient from "../../../components/Product/ingredient"
import Effectivness from "../../../components/Product/effectivness"
import EffectivnessCard from "../../../components/Product/effectivnessCard"
import ActiveIngredients from "../../../components/Product/activeIngredients"
import ActiveIngredient from "../../../components/Product/activeIngredient"
import DescriptionPackshot from "../../../components/Product/descriptionPackshot"
import Container from "/src/components/Product/container"






const Product = () => (
    <Layout lang={"EN"}>
      <Seo title="Neck and décolleté lifting serum - Relift" lang={"EN"} />
        <ProductHero>

            {/* Info */}
            <div data-column>
                <ProductInfo color="magenta">
                    <H1>Neck and décolleté lifting serum</H1>
                    <p>Firmed and lifted effect </p>
                    <ProductPackshot mobile>
                        <StaticImage
                        src="../../images/media/products/relift/products/liftingujace-serum.png"
                        loading="eager"
                        width={580}
                        quality={95}
                        placeholder="none"
                        formats={["auto", "webp", "avif"]}
                        alt="Neck and décolleté lifting serum"
                        />
                        <ProductPackshotBubbles/>
                </ProductPackshot>

                    <ul>
                        <li>Suitable if skin becomes saggy and fine lines appear.</li>
                        <li>Quick, lasting and spectacular lift up effect.</li>
                        <li>Allows for more oxygen to get into dermis and brings energy to your skin.  </li>
                        <li>Improves microbiome and restores protective barrier.</li>
                    </ul>
                    <Container>
                    <ButtonColor as="a" target="_blank" href="https://www.hebe.pl/oxygenetic-liftingujace-serum-do-szyi-i-dekoltu-50-ml-000000000000407512.html" color="magenta">Buy in Hebe</ButtonColor>
                    <ButtonColor as="a" target="_blank" href="https://www.zikodermo.pl/oxygenetic-relift-liftingujace-serum-do-szyi-i-dekoltu-50-ml.html" color="magenta">Buy in Ziko Dermo</ButtonColor>
                    <ButtonColor as="a" target="_blank" href="https://gemini.pl/oxygenetic-relift-vitamin-a-liftingujace-serum-do-szyi-i-dekoltu-50-ml-0107792" color="magenta">Buy in Gemini</ButtonColor>
                    </Container>               
                     </ProductInfo>
            </div>

            {/* Packshot */}
            <div data-column>
                <ProductPackshot>
                        <StaticImage
                        src="../../images/media/products/relift/products/liftingujace-serum.png"
                        loading="eager"
                        width={580}
                        quality={95}
                        placeholder="none"
                        formats={["auto", "webp", "avif"]}
                        alt="liftingujace serum"
                        />
                        <ProductPackshotBubbles/>
                </ProductPackshot>
                <More url="#opis-produktu" color="magenta" text="more"/>
            </div>

            {/* Category Products */}
            <div data-column>
                <ProductSidebar lang={"EN"}>
                    <Link to="/en/products/smoothing-serum">
                        <StaticImage
                             src="../../images/media/products/relift/products/wygladzajace-serum.png"
                            loading="eager"
                            // width={142}
                            quality={95}
                            placeholder="blurred"
                            formats={["auto", "webp", "avif"]}
                            alt="Smoothing eye serum"
                        />
                    </Link>
                    <Link to="/en/products/anti-aging-serum">
                        <StaticImage
                             src="../../images/media/products/relift/products/odmladzajace-serum.png"
                            loading="eager"
                            // width={142}
                            quality={95}
                            placeholder="blurred"
                            formats={["auto", "webp", "avif"]}
                            alt="odmladzajace serum"
                        />
                    </Link>
                    <Link to="/en/products/ultra-repair-serum">
                        <StaticImage
                             src="../../images/media/products/relift/products/ultranaprawcze-serum.png"
                            loading="eager"
                            // width={142}
                            quality={95}
                            placeholder="blurred"
                            formats={["auto", "webp", "avif"]}
                            alt="ultranaprawcze serum"
                        />
                    </Link>

                </ProductSidebar>
            </div>
            {/* <BubbleBg/> */}
        </ProductHero> 
        <section id="opis-produktu">

            {/* TABS */}
            <Tabs color="magenta">

                {/* OPIS */}
                <div label="Description" className="tab-list-active">
                <ProductDescription color="magenta">
                    <div className="descriptionRow">
                        <div className="column">
                            <div className="text--left">

                                <p><strong>Oxygenetic neck and décolleté lifting serum</strong></p>

                                <p>
                                      Anti-ageing formula for thin, delicate and loose skin of your neck and décolletage.  Lift-up serum to enhance collagen-elastin structure for improved firmness and elasticity of skin texture and prevent fibre thermal decomposition. 
                                </p>
                                <p>
                                This serum is anti-ageing golden standard for very thin and getting-loose skin to make it visibly lifted and firmed. This serum smoothes deep lines and wrinkles, prevents new to form and slows down ageing processes in the skin. Shields the skin with the protective barrier to keep epidermis hydrated and keep in moisture.  Feverishly oxygenating minerals in this serum revive for long lasting skin radiance and glow.
                                </p>
                            </div>
                        </div>
                        <div className="column">
                            <DescriptionPackshot>
                                <StaticImage
                                src="../../images/media/products/relift/products/liftingujace-serum_single.png"
                                loading="lazy"
                                width={534}
                                quality={95}
                                placeholder="none"
                                formats={["auto", "webp", "avif"]}
                                alt="liftingujace serum"
                             />
                            </DescriptionPackshot>
                        </div>
                        <div className="column">
                            <div className="text--right">
                                <p><strong>How to use:</strong></p>
                                <p>Apply every morning and evening on cleansed skin of the neck and décolletage. For external use only. Avoid direct contact with eyes. Avoid exposure to the sun without using a preparation with a UV filter.</p>
                                <br/>
                                <p><strong>Volume:</strong></p>
                                <p>50ml</p>
                                <Relift/>
                            </div>
                        </div>
                    </div>        
                </ProductDescription>
                </div>

                {/* BAZA */}
                <div label="Base">
                <Ingredients>
                    <Ingredient name="Retinol with adenosine" desc="is a golden standard for anti-ageing, the combination that works effectively to replenish skin cells by allowing new plump skin cells to form. Stimulates collagen production  to keep the skin looking youthful. Trusted for real results. Demonstrated a sinificant reduction in the appearance of wrinkles by strengthening fibroblast proliferation in the dermal layer and promoting collagen synthesis. You will surely notice smooth, firmed and refined complexion" />
                    <Ingredient name="Blend of oxygentaing minerals" desc="Minerals are the component which binds oxygen molecules which are absorbed by the skin and stimulates metabolic processes in the skin so that more energy is released and skin cells start to function properly for healthy and radiant look." />
                    <Ingredient name="Synbiotics" desc="A blend of prebiotics and probiotics which fortify microbiom, our natural protective barrier, to protect against environmental stressors. Hydrate, nourish and smooth your skin." />
                </Ingredients>
                </div>

                {/* SKŁADNIKI AKTYWNE */}
                <div label="Active ingredients">
                <ActiveIngredients>
                    <ActiveIngredient name="Hyaluronic acid" desc="a natural lubricant and a powerful humectant (substance whthat binds to water molecules to retain moisture in the skin and joints for a youthful-looking, plump appearance and structure) reduces the effects og aging by increasing skin elasticity. Hyaluronic acid a staplein your skincare routine." />
                    <ActiveIngredient name="Macadamia nut oil" desc="Macadamia nut oil has a chemical profile similar to sebum, i.e. the fatty acid secreted by the skin that makes the skin plump and dewy, which decreases with age. This ingredient repels water causing droplets to form known as hydrophobic which create coating on the skin to ensure moisture, softness and smoothness to enjoy plump and dewy skin." />
                </ActiveIngredients>
                </div>

                {/* SKUTECZNOŚĆ */}
                <div label="Effectivness">
                <Effectivness>
                    <EffectivnessCard color="magenta" percentage="90" desc="badanych potwierdza, że produkt wykazuje skuteczne działanie przeciwzmarszczkowe"/>
                    <EffectivnessCard color="magenta" percentage="100" desc="badanych potwierdza, że po zastosowaniu skóra staje się bardziej miękka"/>
                    <EffectivnessCard color="magenta" percentage="93" desc="badanych potwierdza, że regularne stosowanie zwiększa napięcie skóry zapewniając efekt liftingu"/>
                </Effectivness>
                </div>
            </Tabs>
        </section>
    </Layout>
)

export default Product
